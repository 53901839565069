<template>
  <div class="case-creation__step case-creation__Case-information">
    <div class="case-creation__card">
      <div class="case-creation__heading edit-view">
        <div class="title-with-icon">
          <span class="icon">
            <img
              src="@/assets/images/icons/icon-case-info.svg"
              alt="icon"
              class="img-fluid"
            />
          </span>
          <h3 class="title">Case Information</h3>
        </div>
      </div>
      <div class="case-creation__body pb-0">
        <el-row :gutter="15">
          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">Case Type</h4>
              <ul>
                <li
                  v-for="(
                    caseType, index
                  ) of getReceivedSendOutInformation.case_types"
                  :key="index"
                >
                  {{ caseTypeMap[caseType] }}
                </li>
              </ul>
            </div>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">Laboratory</h4>
              <p class="value" v-if="getReceivedSendOutInformation.lab">
                {{ getReceivedSendOutInformation.lab.name }}
              </p>
              <p class="value" v-else>--</p>
            </div>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">Collection Date</h4>
              <p class="value">
                {{
                  momentWithTimezone(
                    getReceivedSendOutInformation.collection_date,
                    "MM-DD-yyyy"
                  )
                }}
              </p>
            </div>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">Received Date</h4>
              <p class="value">
                {{
                  momentWithTimezone(
                    getReceivedSendOutInformation.received_date,
                    "MM-DD-yyyy"
                  )
                }}
              </p>
            </div>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">Ordering Facility</h4>
              <p class="value" v-if="getReceivedSendOutInformation.hospital">
                {{
                  getReceivedSendOutInformation
                    ? getReceivedSendOutInformation.hospital.name
                    : "--"
                }}
              </p>
              <p class="value" v-else>--</p>
            </div>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">Ordering Facility Branch</h4>
              <p
                class="value"
                v-if="getReceivedSendOutInformation.hospital_branch"
              >
                {{ getReceivedSendOutInformation.hospital_branch.display_name }}
              </p>
              <p class="value" v-else>--</p>
            </div>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">Ordering Physician</h4>
              <p
                class="value"
                v-if="getReceivedSendOutInformation.ordering_physician"
              >
                {{ getReceivedSendOutInformation.ordering_physician.name }}
              </p>
              <p class="value" v-else>--</p>
            </div>
          </el-col>

          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">Referring Physician</h4>
              <p
                class="value"
                v-if="getReceivedSendOutInformation.referring_physician"
              >
                {{ getReceivedSendOutInformation.referring_physician.name }}
              </p>
              <p class="value" v-else>--</p>
            </div>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">
                Bill To
              </h4>
              <p
                class="value"
                v-if="getReceivedSendOutInformation.billing_type"
              >
                {{ getReceivedSendOutInformation.billing_type }}
              </p>
              <p class="value" v-else>--</p>
            </div>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">Order ID</h4>
              <p
                class="value"
                v-if="getReceivedSendOutInformation.requisition_order_id"
              >
                {{ getReceivedSendOutInformation.requisition_order_id }}
              </p>
              <p class="value" v-else>--</p>
            </div>
          </el-col>

          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">Specimen Collector</h4>
              <p
                class="value"
                v-if="getReceivedSendOutInformation.specimen_collector"
              >
                {{ getReceivedSendOutInformation.specimen_collector }}
              </p>
              <p class="value" v-else>--</p>
            </div>
          </el-col>
          <el-col :xl="16" :lg="16" :md="16" :sm="16" :xs="16">
            <div class="input-value icd-codes">
              <div class="indicatives">
                <h4 class="name">ICD-10 (Indicative)</h4>
                <ul>
                  <li
                    class="value"
                    v-for="(
                      icd_10_code, index
                    ) in getReceivedSendOutInformation.icd_10_codes"
                    :key="index"
                  >
                    {{ icd_10_code }}
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
            <div class="input-value view-cases">
              <div
                class="speciment-type-view"
                v-if="getReceivedSendOutInformation && getReceivedSendOutInformation.sample_types"
              >
                <div>
                  <h4 class="name">Specimen Types</h4>
                  <ul
                    class="case_type"
                    v-if="
                      getReceivedSendOutInformation.sample_types &&
                      getReceivedSendOutInformation.sample_types.length
                    "
                  >
                    <li
                      class="value"
                      v-for="(
                        sampleType, index
                      ) in getReceivedSendOutInformation.sample_types"
                      :key="index"
                    >
                      {{ caseTypeMap[sampleType.case_type] }}
                    </li>
                  </ul>
                  <ul v-else>
                    --
                  </ul>
                </div>
                <div>
                  <h4 class="name">&nbsp;</h4>

                  <ul
                    class="speciment_type"
                    v-if="
                      getReceivedSendOutInformation.sample_types &&
                      getReceivedSendOutInformation.sample_types.length
                    "
                  >
                    <li
                      v-for="(
                        sampleType, index
                      ) in getReceivedSendOutInformation.sample_types"
                      :key="index"
                    >
                      <span
                        v-if="
                          sampleType.sample_types &&
                          sampleType.sample_types.length
                        "
                      >
                        <span
                          v-for="(sample, index) in sampleType.sample_types"
                          :key="index"
                        >
                          {{ sample }}
                          <span
                            v-if="index + 1 < sampleType.sample_types.length"
                            >,</span
                          >
                        </span>
                      </span>
                      <span v-else> -- </span>
                    </li>
                  </ul>
                  <ul v-else>
                    --
                  </ul>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import caseConstants from "@/config/constants/caseConstants";
export default {
  computed: {
    ...mapGetters("sendOuts", ["getReceivedSendOutInformation"]),
  },
  data() {
    return {
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
    };
  },
};
</script>

<style lang="scss" scoped>
.err {
  color: #c14e4e;
}
</style>
